var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ckeditor', {
    attrs: {
      "editor": _vm.editor,
      "config": _vm.editorConfig
    },
    model: {
      value: _vm._text,
      callback: function callback($$v) {
        _vm._text = $$v;
      },
      expression: "_text"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }